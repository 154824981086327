<template>
  <div class="flex min-h-screen overflow-hidden surface-0 align-items-center justify-content-center min-w-screen">
    <div class="grid p-2 justify-content-center lg:p-0" style="min-width: 80%">
      <div class="col-12 xl:col-6 tw-shadow-xl tw-border-2 tw-border-gray-200 tw-rounded-2xl">
        <div class="w-full h-full px-4 m-0 py-7 md:w-10 mx-auto">
          <router-link :to="{name: 'login'}" class="block font-bold no-underline ml-2 mb-3 cursor-pointer text-primary tw-text-xl">
            <i class="pi pi-arrow-left mr-2"></i>
            Back to Login
          </router-link>
          <div class="mt-5 text-center col-12 xl:mt-0">
            <router-link :to="{ name: 'landing' }">
              <img
                src="~@/assets/images/logo.png"
                alt="Sakai logo"
                class="mx-auto"
                style="height: 60px"
              />
            </router-link>
          </div>

          <div class="text-center tw-mb-8 tw-mt-10">
            <h1 class="tw-text-4xl tw-font-bold">Forgot Password</h1>
          </div>

          <form @submit.prevent="doSendLink()" class="w-full mx-auto ">
            <p class="text-center tw-mb-8">
              Enter your email <br> and we'll send you OTP to verify your email.
            </p>
            <label for="email1" class="block mb-2 text-xl font-medium text-900">Email</label>
            <InputText
              id="email1"
              v-model="form.email"
              type="text"
              class="w-full mb-3"
              placeholder="Email"
              style="padding: 1rem"
              :disabled="form.is_submit"
            />

            <Button type="submit"
                class="p-button p-component w-full p-3 text-xl tw-mt-12"
                :disabled="form.is_submit">
                <span v-if="!form.is_submit" class="p-button-label">Send Link</span>
                <i class="pi pi-spin pi-spinner p-button-label tw-text-xl" v-else></i>
            </Button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      form: {
        email: '',
        is_submit: false,
      },
    }
  },
  methods: {
    doSendLink() {
      this.form.is_submit = true
      this.$http
        .post(`${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/auth/forgot-password`, this.form)
        .then(response => {
          this.form.is_submit = false
          if (response.data.code !== 200) return
          this.$router.push({
            name: 'verify-forgot-password',
            params: {token: response.data.data.token}
          })
        }).catch(error => {
          this.form.is_submit = false
          this.$toast.add({
            severity: 'error',
            summary: 'Error!',
            detail: error.response.data.message,
            life: 3000,
          })
        })
    },
  },
};
</script>

<style scoped></style>
